import React from 'react'

export const Ecosystem = () => {
  return (
    <> <div className="bg">
   
    <div className=" p60 work-section2" id="Ecosystem">
      <div className="container">
        <h2 className=" hadding mb-2 text-center">
          Pirates <span> Ecosystem</span>
        </h2>
        <p className="text-center   mb-md-5">
          A comprehensive DeFi solution, encompassing a non-custodial
          wallet, swap aggregator,  payment
          <br /> gateway, AI-powered P2P lending, and perpetual
          futures/options trading for seamless crypto management.
        </p>
        <div className="row justify-content-center">
          {/* <div className="col-md-4 mb-3 mb-md-4  ">
            <div className="usp_box_box">
              <i className="fa fa-credit-card-alt" />
              <h5>DeFi Debit Cards </h5>
              <p>
                Prioritize your financial privacy with anonymous
                transactions, eliminating the need for KYC procedures,
                and ensure security with Pirates DeFi Debit Cards.
              </p>
            </div>
          </div> */}
          <div className="col-md-4 mb-3 mb-md-4  ">
            <div className="usp_box_box">
              <i className="fa fa-globe" />
              <h5>Payment Gateway </h5>
              <p>
                Seamlessly accept cryptocurrencies in your online
                business, expanding payment options for your customers.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-4  ">
            <div className="usp_box_box">
              <i className="fa fa-credit-card" />
              <h5>DeFi Wallet</h5>
              <p>
                A secure, non-custodial wallet supporting multiple
                blockchains, providing you full control over your
                assets.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-4  ">
            <div className="usp_box_box">
              <i className="fa fa-exchange" />
              <h5>Swap Aggregator </h5>
              <p>
                Effortlessly find the most favorable rates by instantly
                comparing quotes and fees across various decentralized
                exchanges (DEXes).
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-4  ">
            <div className="usp_box_box">
              <i className="fa fa-money" />
              <h5>AI-Powered P2P Lending </h5>
              <p>
                Let our powerful AI analyze lending options for you,
                ensuring you get the best and most profitable lending
                solutions.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-4  ">
            <div className="usp_box_box">
              <i className="fa fa-line-chart" />
              <h5>Perpetual Futures &amp; Options </h5>
              <p>
                Engage in long and put options directly from App,
                simplifying your trading experience across different
                assets.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="bglight p60 work-section padding-bottom     position-relative"
      data-scroll-index={2}
    >
      <div className="css-1wn2lqe" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 mb-3 mb-md-4  ">
            <img className="img-fluid" src="img/word.png" alt="coin" />
          </div>
          <div className="col-md-6 mb-3 mb-md-4  ">
            <h2 className=" hadding mb-3 mt-md-4">
              <span>Pirates-World </span> Crypto Adoption
            </h2>
            <p>
              {" "}
              Pirates Finance is committed to bridging the gap between
              the crypto Piratesm and Pirates-life utility. Our mission
              extends beyond digital transactions, as we believe in
              bringing cryptocurrency to the forefront of everyday
              experiences. We understand that true adoption goes beyond
              the virtual Piratesm, and that's why we are pioneering
              Pirates-life integration like never before. ​
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="  pt-4 pt-md-5 ">
      <div className="container">
        <h2 className=" hadding mb-3 text-center  ">
          What is <span>Pirates?</span>
        </h2>
        <p className="  mb-4 text-center   ">
          Pirates stands as a dynamic DeFi token built upon the Binance
          mainnet, adhering to the BEP20 standard. With a total supply
          of 600 Trillion (6,00,000,000,000,000), Pirates powers the Pirates
          Finance ecosystem, driving decentralized transactions and
          fueling the innovative financial solutions that define our
          platform's essence.
        </p>

        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-12 mb-4">
            <div className="cryto_feature">
              <div>
                <img src="img/n1.png" alt="Secure Wallet" />
              </div>
              <div>
                <h3>Secure Wallet</h3>
                <p>
                  Pirates Coin provides a secure wallet to its users for
                  safe transactions and works with a simple KYC
                  procedure which is easy to understand and manage by
                  its users. One can access this by using valid user
                  credentials.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 mb-4">
            <div className="cryto_feature">
              <div>
                <img src="img/n2.png" alt="Exchange User Interface" />
              </div>
              <div>
                <h3>Exchange User Interface</h3>
                <p>
                  Time as well as potential are equally important.
                  Pirates Coin uses a simple, yet an elegant user
                  interface that permits its users a friendly juncture
                  of transaction. We aim at reducing users’ efforts as
                  much as possible by providing a smooth terminal. It is
                  also conditioned in such a way that it gives a
                  comprehensive picture of the process.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 mb-4">
            <div className="cryto_feature">
              <div>
                <img src="img/n3.png" alt="Secure Storage" />
              </div>
              <div>
                <h3>Secure Storage</h3>
                <p>
                  We provide a detailed description of our distributed
                  and secure storage solutions. We talk about how this
                  approach protects user data and digital resources from
                  data breaches and other security threats. By
                  showcasing our commitment to secure storage solutions,
                  we aim to build confidence with our users and
                  demonstrate our commitment to protecting their assets.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 mb-4">
            <div className="cryto_feature">
              <div>
                <img
                  src="img/n4.png"
                  alt="A Functional Trading Engin"
                />
              </div>
              <div>
                <h3>A Functional Trading Engine</h3>
                <p>
                  A fully-operational trading engine is the fundamental
                  component for a trade exchange. We, therefore, have a
                  strong and active exchange engine compatible for all
                  users to keep a track of their exchange hassle-free
                  and maintain calculated balance records.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="  pt-4 pt-md-5 ">
      <div className="container">
        <h2 className=" hadding mb-3 text-center  ">
          Use <span>Cases</span>
        </h2>

        <div className="row">
          <div className="col-md-6">
            <div className="cryto_feature2">
              <div className="icon_box">
                <img
                  src="img/info-bg-1.png"
                  alt="v"
                  className="round_img"
                />
                <img src="img/uc1.png" alt="#" className="d_img" />
              </div>
              <div>
                <h3>Profitable Platform</h3>
                <p>
                  The Pirates platform is designed to be profitable for
                  users, with a range of features and capabilities that
                  can help investors to earn a good return on their
                  investment. With advanced market analysis tools,
                  real-time data, and sophisticated algorithms, the
                  Pirates platform provides users with the insights they
                  need to make informed trading decisions and maximize
                  their profits.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="cryto_feature2">
              <div className="icon_box">
                <img
                  src="img/info-bg-1.png"
                  alt="v"
                  className="round_img"
                />
                <img src="img/1f2.png" alt="1f2" className="d_img" />
              </div>
              <div>
                <h3>Solar Pannel</h3>
                <p>
                  Solar panels, also called photovoltaic (PV) panels,
                  are devices that convert light from the sun into
                  electricity. They are composed of several solar cells,
                  usually made of silicon, that produce DC electricity
                  when exposed to sunlight. Solar panels are a renewable
                  and sustainable source of energy that helps reduce
                  fossil fuel dependency and greenhouse gas emissions.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="cryto_feature2   mb-mb-0">
              <div className="icon_box">
                <img
                  src="img/info-bg-1.png"
                  alt="v"
                  className="round_img"
                />
                <img src="img/uc3.png" alt="#" className="d_img" />
              </div>
              <div>
                <h3>Smart Treasure</h3>
                <p>
                  The Pirates platform also includes a smart treasure,
                  which is a unique feature that enables users to store
                  and manage their cryptocurrencies in a secure and
                  efficient manner. With advanced security features,
                  multi-factor authentication, and sophisticated
                  encryption, the smart treasure ensures that users'
                  cryptocurrencies are safe and secure, no matter where
                  they are in the world.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="cryto_feature2 mb-0 mb-mb-0">
              <div className="icon_box">
                <img
                  src="img/info-bg-1.png"
                  alt="v"
                  className="round_img"
                />
                <img src="img/1f4.png" alt="#" className="d_img" />
              </div>
              <div>
                <h3>Electric Vehicle</h3>
                <p>
                  Electric vehicles (EVs) have seen a surge in
                  popularity in recent years, and this trend is only
                  expected to continue into the future. One major
                  advantage of EVs is their lower operating costs. They
                  require less maintenance than traditional
                  gasoline-powered vehicles, and the cost of electricity
                  is generally much lower than the cost of gasoline.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></>
  )
}
