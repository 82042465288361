import React, { useEffect, useState, useRef } from "react";
import { Header } from "../Common/Header";
import { Footer } from "../Common/Footer";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits, parseEther } from "ethers";
import Big from "big.js";
import {
  tokenabi,
  tokenaddress,
  stakeabi,
  stakeaddress,
  launchpadFarmingAddress,
  launchpadFarmingAbi,
  chainIds,
} from "../constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StakingInfoBox from "./partials/StakingInfoBox";
export const Staking = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [pageRefreshed, setPageRefreshed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const { updateTheam, theam } = useTheam();
  const [clickedAction, setClickedAction] = useState(null);
  const [action, setAction] = useState("Buy");
  const [amountErr, setAmountErr] = useState("");
  const [walletConnected, setwalletConnected] = useState(false);
  const [stakeamount, setstakeamount] = useState(0);


  const [spinner, setSpinner] = useState(false);
  const [days, setDays] = useState();
  const [daysLeft, setDaysLeft] = useState(0);
  const [aprr, setAprr] = useState(8);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [tokenbalance, settokenbalance] = useState();
  // const [tokenbalancelp, settokenbalancelp] = useState();
  // const [lockDurations, setlLockDurations] = useState();
  // const [participants, setlParticipants] = useState();
  // const [widthPercentages, setWidthPercentage] = useState(0);
  // const [totalReward, setlTotalReward] = useState();
  // const [rewardBalances, setRewardBalances] = useState();
  const [rewardAmt, setRewardAmt] = useState(0);
  const [allowence, setallowence] = useState(0);

  const [index, setindexs] = useState(1);
  const [inputamount1, setinputamount1] = useState();
  const [inputamount, setinputamount] = useState();
  const [show, setshow] = useState(false);
  const [walletTotalAmount, setWalletTotalAmount] = useState(0);
  const [totalStakeAmount, setTotalStakeAmount] = useState(0);
  const [calculatorAmount, setcalculatorAmount] = useState();

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      toast.error(error);
      console.error("Error in getWeb3Provider:", error);
      throw error; // Propagate the error to the caller or handle as needed
    }
  };
  const gettokenamount = async (e) => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();
      const contract = new Contract(tokenaddress, tokenabi, provider);
      const balance = await contract.balanceOf(address);
      const originalValues = formatUnits(balance, 18);
      const roundedValues = parseFloat(originalValues).toFixed(4);
      const trimmedValues = parseFloat(roundedValues).toString();
      // let walletBal = formatUnits(balance, 18);

      // walletBal = Number(walletBal);
      settokenbalance(trimmedValues);
      const contracts = new Contract(stakeaddress, stakeabi, provider);
      const balances = await contracts.reward(address, e);
      const originalValue = formatUnits(balances.amount, 18);
      const roundedValue = parseFloat(originalValue).toFixed(8);

      const trimmedValue = parseFloat(roundedValue).toString();
      setRewardAmt(trimmedValue);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    checkapproval();
  }, [inputamount]);

  const checkapproval = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const contract = new Contract(tokenaddress, tokenabi, provider);
      const balance = await contract.allowance(address, stakeaddress);
      // console.log(formatUnits(balance,18),"bala")
      console.log(parseFloat(inputamount), "balancein");
      // console.log(parseFloat(inputamount), "balance");
      const allowence = formatUnits(balance, 18);
      setallowence(allowence);
      console.log(parseFloat(allowence), "balanceall");

      if (parseFloat(allowence) >= parseFloat(inputamount)) {
        setshow(true);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const approve = async () => {
    try {
      
      if (!isConnected) {
        toast.error("User Disconnected");
      }
      const signer = await getSignerOrProvider(true);

      const provider = await getSignerOrProvider();

      if (isConnected) {
        // if (userAddress == null) {
        //   toast.dismiss();
        //   toast.error("Please connect with metamask");
        //   return;
        // }
        setButtonDisable(true);

        if (chainId != chainIds) {
          toast.dismiss();
          toast.error("Please select binance smart chain");
          return;
        }
        if (!inputamount) {
          toast.dismiss();
          toast.error(" Please enter amount");
          return;
        }
        const contract = new Contract(tokenaddress, tokenabi, signer);

        const tx = await contract.approve(
          stakeaddress,
          parseEther(inputamount)
        );
        setSpinner(true);

        await tx.wait();
        toast.success("Approval successful");
        setButtonDisable(false);
        setshow(true);
        setSpinner(false);
       
      } else {
        setButtonDisable(false);
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }

      // if (connectWallet == false) {
      //   toast.dismiss();
      //   toast.error("Please connect with metamask");
      //   return;
      // }
    } catch (err) {
      setSpinner(false);
      setButtonDisable(false);
      console.error(err);
    }
  };
  const handleChange1 = async (e) => {
    setinputamount1(e.target.value);
  };
  const MaxWalletAmt = () => {
    const num1 = new Big(stakeamount);
    const num2 = new Big(rewardAmt);
    let totalAmount = num1.plus(num2).toString();

    totalAmount = totalAmount.slice(0, totalAmount.lastIndexOf(".") + 6);

    if (totalAmount) {
      setinputamount1(totalAmount);
    } else {
      console.error(
        "Invalid totalAmount. Please check stakeamount and rewardAmt."
      );
    }
  };
  const validNumber = (e) => {
    if (!/[\d.]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleChange = async (e) => {
    setinputamount(e.target.value);
    setButtonDisable(false);
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const contract = new Contract(tokenaddress, tokenabi, provider);
      const balance = await contract.allowance(address, stakeaddress);
      const balanceBN = formatUnits(balance, 18);
      console.log(typeof e.target.value, "aval");
      console.log(balanceBN, "aval");

      if (parseFloat(balanceBN) >= parseFloat(e.target.value)) {
        setshow(true);
        console.log("this is here");
      } else {
        setshow(false);
        console.log("this is here not");
      }
    } catch (err) {
      console.error(err);
    }
  };
  const depositamount = async (type, trans_type) => {
    try {
      if (!isConnected) {
        toast.error("User Disconnected");
      }
      await checkapproval();
      setButtonDisable(true);

      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      if (address == address) {
        if (chainId != chainIds) {
          toast.dismiss();
          toast.error("Please select binance smart chain");
          return;
        }
        if (inputamount > tokenbalance) {
          toast.dismiss();
          toast.error("You don't have any token");
          return;
        }
      console.log(tokenbalance,"tokenbalance");

        const contract = new Contract(stakeaddress, stakeabi, signer);
        const tx = await contract.stake(parseEther(inputamount), index);
     
        setSpinner(true);
        await tx.wait();
        toast.success("Success");
        setTimeout(()=>{
          window.location.reload();
        },1500)
        
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }
    } catch (err) {
      setSpinner(false);
      setButtonDisable(false);
      if (
        err.message.includes(
          `execution reverted: "You don't have enough BABT token"`
        )
      ) {
        toast.error("You don't have enough BABT token to verify our Kyc !");
      }
      if (err.message.includes(`execution reverted:  "You are blocked"`)) {
        toast.error("You are blocked");
      }
      if (
        err.message.includes(
          `execution reverted: "ERC20: insufficient allowance"`
        )
      ) {
        toast.error("Insufficient allowance");
      }

      if (
        err.message.includes(
          "execution reverted: ERC20: transfer amount exceeds balance"
        )
      ) {
        toast.error("You don't have enough balance to stake !");
      }
    }
  };
  const tabData = [
    { index: 1, label: "30 Days", tab: "one", apr: 8 },
    { index: 2, label: "60 Days", tab: "two", apr: 16 },
    { index: 3, label: "90 Days", tab: "three", apr: 24 },
    { index: 4, label: "120 Days", tab: "four", apr: 32 },
    { index: 5, label: "180 Days", tab: "five", apr: 49 },
    { index: 6, label: "365 Days", tab: "six", apr: 100 },
  ];
  const getstakeamount = async (e) => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();
      const contract = new Contract(stakeaddress, stakeabi, provider);
      const balance = await contract.stakesamount(address, e);
      console.log(balance.lockTime, "hhh");
      setstakeamount(formatUnits(balance[0], 18));
      // const contract1 = new Contract(
      //   launchpadFarmingAddress,
      //   launchpadFarmingAbi,
      //   provider
      // );

      const targetTimestamp12 = balance.lockTime;
      const toStr = targetTimestamp12.toString();
      const targetTimestamp = Number(toStr);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const timeDifferenceInSeconds = targetTimestamp - currentTimestamp;
      const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60);

      console.log(targetTimestamp, "hhh123");
      setDaysLeft(timeDifferenceInMinutes);

      // const balance1 = await contract1.userDeposits(address);
      // setstakeamount1(formatUnits(balance1[0], 18));
      // const balance2 = await contract1.accShare();

      // setstakeamount2(formatUnits(balance2, 6));
      // const balance3 = await contract1.rewardBalance();

      // setstakeamount3(formatUnits(balance3, 18));
      // const balance4 = await contract1.totalReward();
      // setstakeamount4(formatUnits(balance4, 18));
      // const balance5 = await contract1.rewPerBlock();
      // setstakeamount5(formatUnits(balance5, 18));
      // const balance6 = await contract1.calculate(address);
      // setstakeamount6(formatUnits(balance6, 18));
      // const balance8 = await contract1.userDeposits(address);
      // setstakeamount8(formatUnits(balance8[0], 18));
      // setstakeamount9(balance8[1]);
      // const cBlocks = await contract1.currentBlock();
      // setCblock(cBlocks);
      // const days = await contract1.lockDuration();
      // const toStr1 = days.toString();
      // const targetTimestamp1 = Number(toStr1);
      // const toStr2 = balance8[1].toString();
      // const targetTimestamp2 = Number(toStr2);
      // const toStr3 = cBlocks.toString();
      // const targetTimestamp3 = Number(toStr3);

      // setlLockDurations(targetTimestamp1);
      // console.log("LOK", cBlocks);

      // const timing = targetTimestamp2 + targetTimestamp1 * 1200;
      // console.log("timing", timing);
      // const nowblock = timing - targetTimestamp3;
      // console.log("nowblock", nowblock);
      // if (nowblock > 0) {
      //   const newTiming = nowblock / 28800;
      //   setEblock(newTiming.toFixed(0));
      // } else {
      //   setEblock(0);
      // }

      // const balance7 = await contract1.stakedBalance();
      // setstakeamount7(formatUnits(balance7, 18));

      const Totalbalance = await contract.totalStakedAmount(address);

      setTotalStakeAmount(formatUnits(Totalbalance, 18));
    } catch (err) {
      console.error(err);
    }
  };
  const withdrawstake = async (type, trans_type) => {
    try {
      if (!isConnected) {
        toast.error("User Disconnected");
      }

      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();

      if (address == address) {
        if (chainId != chainIds) {
          toast.dismiss();
          toast.error("Please select binance smart chain");
          return;
        }
        setButtonDisable(true);

        let datas = {
          wallet_address: address,
          type,
        };
        // let resp = await checkMultipleRequest(datas);
        // if (!resp.status) {
        //   toast.dismiss();
        //   toast.error(resp.message);
        //   return;
        // }
        const contract = new Contract(stakeaddress, stakeabi, signer);
        const tx = await contract.withdraw(index);
        setSpinner(true);
        const transactionHash = tx.hash;

        const data = {
          address,
          inputamount: inputamount1,
          trans_id: transactionHash,
          days,
          type,
          trans_type,
        };

        // const res = await stackingApply(data);
        await tx.wait();
        toast.success("Success");
        // redirect();
        // if (res.status) {
        //   toast.dismiss();
        //   toast.success(res.message);
        //   await tx.wait();
        //   setshow(true);
        //   setSpinner(false);
        //   setButtonDisable(false);
        //   redirect();
        // } else {
        //   toast.dismiss();
        //   toast.error(res.message);
        // }
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }
    } catch (err) {
      console.log(err.message, "error is ");
      if (
        err.message.includes(
          `execution reverted: "Your withdraw time is not reached yet!"`
        )
      ) {
        toast.error("Your withdraw time is not reached yet!");
      }
      if (
        err.message.includes(
          `execution reverted: "You don't have enough stake to withdraw"`
        )
      ) {
        toast.error("You don't have enough stake to withdraw");
      }
      if (err.message.includes("ERC20: transfer amount exceeds balance")) {
        toast.error("Amount Exceeds wallet balance");
      }
      if (
        err.message.includes(
          `execution reverted: "No available stakes to withdraw"`
        )
      ) {
        toast.error("No available stakes to withdraw");
      }
      toast.error(err);

      console.error(err);
    } finally {
      setButtonDisable(false);
    }
  };

  useEffect(() => {
   
    getstakeamount(1);
    gettokenamount(1);
  }, [address]);

  const setindex = async (e, apr) => {
    setindexs(e);
    setAprr(apr);
    getstakeamount(e);
    gettokenamount(e);

    const durationMapping = { 1: 30, 2: 60, 3: 90, 4: 120, 5: 180, 6: 365 };
    setDays(durationMapping[e]);
  };
  const MaxAmount = (amt) => {
    setinputamount(amt);

    if (parseFloat(allowence) <= parseFloat(amt)) {
      setshow(false);
    }
  };
  React.useEffect(() => {
    gettokenamount();
  }, []);
  return (
    <>
      <Header />

      <section className="in_page bg ">
        <div className="container">
          <h2 className=" hadding mb-2 mt-2 mr-md-4 mb-4 text-center ">
            <span className="color_g"> Stake</span> Pirates Token and get
            <span className="color_g"> 100%</span> ROI
          </h2>
          <div className="row  ">
            <div className="col-12 col-md-7 mb-4 m-auto">
              <div className="no-hover staking-card single-staking launchpad_box">
                <div className=" " id="STAKE_tab">
                  <h3 className="mb-2">Staking Panel </h3>
                  <h5 className="m-0">Lock Periods</h5>
                  <ul
                    className="nav nav-tabs staking-tabs border-0 mb-4 mt-3"
                    id="myTab"
                    role="tablist"
                  >
                    {tabData.map(({ index, label, tab, apr }) => {
                      return (
                        <li
                          key={index}
                          className="nav-item"
                          role="presentation"
                        >
                          <a
                            onClick={() => setindex(index, apr)}
                            className={`tab-link${
                              index === 1 ? " active" : ""
                            }`}
                            id={`tab-${tab}-tab`}
                            data-toggle="tab"
                            href={`#tab-${tab}`}
                            role="tab"
                            aria-controls={`tab-${tab}`}
                            aria-selected={index === 1 ? "true" : "false"}
                          >
                            {label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>

                  <div className="tab-content mt-3" id="myTabContent">
                    {tabData.map((tab, index) => (
                      <div
                        key={index}
                        className={`tab-pane fade ${
                          index === 0 ? "active show" : ""
                        }`}
                        id={`tab-${index + 1}`}
                        role="tabpanel"
                        aria-labelledby={`tab-${index + 1}-tab`}
                      >
                        <StakingInfoBox
                          stakeamount={stakeamount}
                          rewardAmt={rewardAmt}
                          tokenbalance={tokenbalance}
                          daysLeft={daysLeft}
                          apr={aprr}
                        />
                      </div>
                    ))}
                  </div>
                  {/* <div className="input-box my-4">
                    <div className="input-area d-flex flex-column flex-md-row mb-3">
                      <div className="input-text">
                        <input
                          type="tel"
                          placeholder="Enter PIRATES Amount"
                          defaultValue=""
                          className="form-control"
                        />
                        <button>Max</button>
                      </div>
                      <button className=" btn1 mt-2 mt-md-0 ml-md-3 ">
                        <span className="l_hide_text">Approve</span>
                      </button>
                    </div>
                    <div className="input-area d-flex flex-column flex-md-row">
                      <div className="input-text">
                        <input
                          type="tel"
                          disabled=""
                          placeholder="Enter PIRATES Amount"
                          defaultValue=""
                          className="form-control"
                        />
                        <button style={{ zIndex: 10000 }}>Max</button>
                      </div>
                      <button className=" btn1 mt-2 mt-md-0 ml-md-3 ">
                        <span className="l_hide_text">Withdraw</span>
                      </button>
                    </div>
                  </div> */}
                  <div className="input-box my-4">
                    <div className="input-area d-flex flex-column flex-md-row mb-3">
                      <div className="input-text">
                        <input
                          onChange={handleChange}
                          value={inputamount}
                          type="tel"
                          onKeyPress={validNumber}
                          placeholder="Enter PIRATES Amount"
                          className="form-control"
                        />
                        <button
                          onClick={() => MaxAmount(tokenbalance)}
                          style={{ zIndex: 10000 }}
                        >
                          Max
                        </button>
                      </div>

                      {!show ? (
                        <button
                          onClick={() => {
                            setClickedAction("approve");
                            approve();
                          }}
                          className={`btn1 mt-2 mt-md-0 ml-md-3 ${
                            spinner && clickedAction === "approve"
                              ? "btn_loader11"
                              : ""
                          }`}
                          disabled={
                            buttonDisable ||
                            (spinner && clickedAction !== "approve")
                          }
                        >
                          <span className="l_hide_text">Approve</span>
                          {spinner && clickedAction == "approve" && (
                            <span className="btn_loader"></span>
                          )}
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setClickedAction("deposit");
                            depositamount("deposit", "stacking");
                          }}
                          className="btn1 mt-2 mt-md-0 ml-md-3"
                          // className={`btn1 mt-2 mt-md-0 ml-md-3 ${
                          //   spinner && clickedAction === "deposit"
                          //     ? "btn_loader11"
                          //     : ""
                          // }`}
                          disabled={
                            buttonDisable ||
                            (spinner && clickedAction !== "deposit")
                          }
                        >
                          <span className="l_hide_text">Deposit</span>
                          {spinner && clickedAction === "deposit" && (
                            <span className="btn_loader"></span>
                          )}
                        </button>
                      )}
                    </div>

                    <div className="input-area d-flex flex-column flex-md-row">
                      <div className="input-text">
                        <input
                          onChange={handleChange1}
                          type="tel"
                          onKeyPress={validNumber}
                          value={inputamount1}
                          disabled={true}
                          className="form-control"
                          placeholder="Enter PIRATES Amount"
                        />
                        <button
                          onClick={MaxWalletAmt}
                          style={{ zIndex: "10000" }}
                        >
                          Max
                        </button>
                      </div>

                      <button
                        onClick={() => {
                          setClickedAction("withdraw");
                          withdrawstake("withdrawal", "stacking");
                        }}
                        className={`btn1 mt-2 mt-md-0 ml-md-3 ${
                          spinner && clickedAction === "withdraw"
                            ? "btn_loader11"
                            : ""
                        }`}
                        disabled={
                          buttonDisable ||
                          (spinner && clickedAction !== "withdraw")
                        }
                      >
                        <span className="l_hide_text">Withdraw</span>
                        {spinner && clickedAction === "withdraw" && (
                          <span className="btn_loader"></span>
                        )}
                      </button>
                    </div>
                  </div>

                  <span>
                    Upon withdrawal, your rewards will be automatically sent to
                    your wallet.
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>

          <h4 className="mt-4 text-center">
            <b className="g_text">Staking Feature Will end December 2024 </b>
          </h4>
        </div>
      </section>
      
      <Footer />
    </>
  );
};

export default Staking;
