import React from 'react'

export const Faq = () => {
  return (
    <> 
		<section className="faq p60 "id="Tokenomics">
    <div className="container ">
      <h2
        className=" hadding wow fadeInUpBig text-center mb-md-5 mb-4"
        data-wow-delay="0.3s"
      >
        <span>Tokenomics</span>
      </h2>

      <img src="img/token.png" alt="token" class="img-fluid" />
    </div>
  </section>
	<section className="faq p60 "  >
    <div className="container ">
      <h2
        className=" hadding wow fadeInUpBig text-center"
        data-wow-delay="0.3s"
      >
        Powered <span>By</span>
      </h2>

      <img src="img/powered.png" alt="powered" class="img-fluid" />
    </div>
  </section>

  <section className="faq p60 pt-5"  >
    <div className="container ">
      <h2
        className=" hadding wow fadeInUpBig text-center"
        data-wow-delay="0.3s"
      >
        Exchange <span>Coming Soon</span>
      </h2>

      <img
        src="img/coming_soon.png"
        alt="coming_soon"
        class="img-fluid"
      />
    </div>
  </section>
  <section
    className="faq p60 pb-4 pb-md-5"
    data-scroll-index={3}
    id="faq"
  >
    <div className="container ">
      <div className="row">
        <div className="col-lg-10  col-xs-12 mt-md-2 m-auto">
          <h2
            className=" hadding wow fadeInUpBig text-center"
            data-wow-delay="0.3s"
          >
            FAQ
          </h2>
          <p
            className=" mb-md-4 faq_p wow fadeInUpBig text-center"
            data-wow-delay="0.3s"
          >
            You may have questions, we have the answers.
          </p>
          <div
            className="accordion md-accordion style-2 wow fadeInUpBig"
            data-wow-delay="0.3s"
            id="accordionEx"
            role="tablist"
            aria-multiselectable="true"
          >
            <div className="card">
              <div className="card-header" role="tab" id="headingOne1">
                <a
                  className="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseOne1"
                  aria-expanded="true"
                  aria-controls="collapseOne1"
                >
                  What is Pirates Finance?
                </a>
              </div>
              {/* Card body */}
              <div
                id="collapseOne1"
                className="collapse  "
                role="tabpanel"
                aria-labelledby="headingOne1"
                data-parent="#accordionEx"
              >
                <div className="card-body">
                  Pirates Finance is a DeFi ecosystem that provides a
                  variety of financial tools and services, smart payment gateways, P2P lending,
                  and a secure wallet. It aims to redefine how people
                  conduct financial transactions in the digital age.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" role="tab" id="headingTwo2">
                <a
                  className="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTwo2"
                  aria-expanded="false"
                  aria-controls="collapseTwo2"
                >
                  What is Pirates Token?
                </a>
              </div>
              <div
                id="collapseTwo2"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingTwo2"
                data-parent="#accordionEx"
              >
                <div className="card-body">
                  {" "}
                  Pirates is a community-driven governance token for the
                  Pirates Finance platform, allowing token holders to
                  participate in decision-making processes and help
                  shape the future of the ecosystem.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" role="tab" id="headingTwo3">
                <a
                  className="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTwo3"
                  aria-expanded="false"
                  aria-controls="collapseTwo3"
                >
                  How &amp; Where can I buy Pirates token?{" "}
                </a>
              </div>
              <div
                id="collapseTwo3"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingTwo3"
                data-parent="#accordionEx"
              >
                <div className="card-body">
                  {" "}
                  As of now, Pirates Finance (Pirates) is in its presale
                  phase, which means it is not yet available on
                  traditional cryptocurrency exchanges or DeFi platforms
                  like Uniswap. Beware of potential scams using
                  Pirates's name.
                  <br />
                  The only legitimate way to participate in the Pirates
                  presale is through our official website at
                  https://piratescoin.io. Please exercise caution and
                  ensure you're on the official platform to avoid
                  fraudulent schemes.
                  <br />
                  For detailed instructions on how to buy Pirates
                  tokens, please consult our "How to Buy" guide at
                  https://pirates.com/how-to-buy.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" role="tab" id="headingTwo4">
                <a
                  className="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTwo4"
                  aria-expanded="false"
                  aria-controls="collapseTwo4"
                >
                  When and where will I receive my Pirates tokens?
                </a>
              </div>
              <div
                id="collapseTwo4"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingTwo2"
                data-parent="#accordionEx"
              >
                <div className="card-body">
                  You will be able to claim your Pirates tokens after
                  the presale concludes. This claiming process will take
                  place on Pirates's official website.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" role="tab" id="headingTwo5">
                <a
                  className="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTwo5"
                  aria-expanded="false"
                  aria-controls="collapseTwo5"
                >
                  How many presale stages are there?
                </a>
              </div>
              <div
                id="collapseTwo5"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingTwo5"
                data-parent="#accordionEx"
              >
                <div className="card-body">
                  {" "}
                  Pirates Finance's presale consists of 10 stages. For
                  additional details, please visit:
                  https://pirates.com/pirates.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" role="tab" id="headingTwo6">
                <a
                  className="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTwo6"
                  aria-expanded="false"
                  aria-controls="collapseTwo6"
                >
                  When and where will Pirates launch?
                </a>
              </div>
              <div
                id="collapseTwo6"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingTwo6"
                data-parent="#accordionEx"
              >
                <div className="card-body">
                  Following the presale, Pirates will launch on multiple
                  centralized exchanges, including at least two top-tier
                  exchanges.Stay tuned for official announcements about
                  the specific launch date and trading platforms by
                  following our social media channels.
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" role="tab" id="headingTwo8">
                <a
                  className="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTwo8"
                  aria-expanded="false"
                  aria-controls="collapseTwo8"
                >
                  My wallet is compromised/hacked, what should I do?{" "}
                </a>
              </div>
              <div
                id="collapseTwo8"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingTwo8"
                data-parent="#accordionEx"
              >
                <div className="card-body">
                  {" "}
                  If you suspect that your wallet has been compromised,
                  it's essential to take immediate action to protect
                  your assets. Please follow these steps:
                  <br />
                  Never Share Your Seed Phrase or Private Key: Under no
                  circumstances should you share your Seed Phrase or
                  Private Key with anyone. These are sensitive and
                  should be kept secure.
                  <br />
                  Contact Us: Reach out to us at:
                  https://pirates.com/help to report the issue and seek
                  guidance on how to proceed.
                  <br />
                  Important Note:While we can assist you in claiming
                  pirates tokens in the unfortunate event of your wallet
                  being compromised or hacked, it's crucial to
                  understand that we cannot recover lost funds.
                  Protecting your assets remains a top priority.
                  <br />
                  Beware of Scammers:Remember that we will never ask for
                  your private details, such as your Seed Phrase or
                  Private Key. Be cautious of potential scams and always
                  verify the authenticity of the support you're engaging
                  with.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section></>
  )
}
