import React from 'react'

export const Featured = () => {
  return (
    <>   <section className="text-center  pt-5 ">
    <div className="container mt-md-4">
      <img
        className="img-fluid comingsoon"
        src="img/comingsoon.jpg"
        alt="comingsoon"
      />
    </div>
  </section>
  <div className=" p600  text-center">
    <div className="container">
    <div className="row">
    <div className="col-md-9 m-auto">
      <h2 className=" hadding mb-4 text-center  ">
      Burn 🔥 Feature ?<span> Why Pirates.</span>

      </h2>
      <p className='h5'>We burn monthly and we airdrop winners monthly and referral program $2000 usd referral in coin after conditions met.</p>
   </div>
   </div>
   </div>
   </div>
  <div className=" p600 featured_in  ">
    <div className="container">
      <h2 className=" hadding mb-4 mb-md-5 text-center  ">
        Featured In 
      </h2>
      <marquee behavior="scroll">
        <div>
          <img className=" img-fluid " src="img/f1.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f2.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f3.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f4.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f5.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f6.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f7.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f8.png" alt="coin" />
        </div>
      </marquee>
      <marquee className="mt-5" behavior="scroll" direction="right">
        <div className="   ">
          <img className=" img-fluid " src="img/f1.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f2.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f3.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f4.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f5.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f6.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f7.png" alt="coin" />
        </div>
        <div className="  ">
          <img className=" img-fluid " src="img/f8.png" alt="coin" />
        </div>
      </marquee>
    </div>
  </div></>
  )
}
