import React, { useState, useEffect, useMemo } from "react";
import { Header } from "../Common/Header";
import { Footer } from "../Common/Footer";
import { Featured } from "./partials/Featured";
import { Ecosystem } from "./partials/Ecosystem";
import { Faq } from "./partials/Faq";
import { Line } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { toast } from "react-toastify";
import { BrowserProvider, Contract, formatUnits, parseEther } from "ethers";
// import { BrowserProvider, Contract, formatUnits, parseEther } from "ethers";
import Web3 from "web3";
import {
  chainIds,
  piratesIdoAbi,
  piratesIdoAddress,
  usdtTokenAbi,
  usdtTokenAndAddress,
} from "../constant";
const initialData = {
  labels: ["0.00000001", "0.0000009", "0.00000010", "0.00000090","0.0000019","0.000050"],
  datasets: [
    {
      label: "BUDZ Price",
      data: [], // Data will be set based on the selected range
      fill: false,
      backgroundColor: "green",
      borderColor: "green",
    },
  ],
};
export const Home = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const web3 = new Web3("https://data-seed-prebsc-1-s1.binance.org:8545/");
  const { walletProvider } = useWeb3ModalProvider();
  const [data, setData] = useState(initialData);
  const [selectedRange, setSelectedRange] = useState("All Time");
  const [deadline, setDeadline] = useState(0);
  const [btnDisable, setBtnDisable] = useState(false);
  const [allowance, setAllowance] = useState(0);
  const [amount, setAmount] = useState();
  const [shows, setShows] = useState(false);
  const [amountErr, setAmountErr] = useState("");
  const [dayss, setDayss] = useState([]);
  const [phaseAlloca, setPhaseAlloca] = useState(0);
  const [balance, setBalance] = useState(0);
  const [currentPhase, setCurrentPhase] = useState(0);

  const [currentStage, setCurrentStage] = useState(0);
  const [stages, setStages] = useState([]);
  const [checkT, setCheckT] = useState(false);

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const totalPercentage = (phaseAlloca * 100) / 2400000000000;

  // ================stage=========//

  useEffect(() => {
    const newStages = [];
    for (let i = 0; i <= 5; i++) {
      let progressWidth = "0%";

      if (
        i < currentPhase - 1 ||
        new Date().getTime() >
          dayss[5] * 1000 + (dayss[5] * 1000 - dayss[4] * 1000)
      ) {
        progressWidth = "100%";
      } else if (i === currentPhase) {
        progressWidth = "0%";
      }
      // else if (i == currentPhase - 1&&new Date().getTime>dayss[5]) {
      //   progressWidth = "100%";
      // }

      newStages.push(
        <div key={i}>
          <label>Stage {i + 1}</label>
          <div className="progress">
            <div className="progress-bar" style={{ width: progressWidth }} />
          </div>
          <label></label>
        </div>
      );
    }
    setStages(newStages);
  }, [currentPhase, phaseAlloca, totalPercentage]);

  const memoizedTimeLeft = useMemo(() => {
    return timeLeft;
  }, [timeLeft]);
  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (isConnected) {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        // if (chainId != 97) {
        //   window.alert("Change Your Network to Sepolia Network");
        //   throw new Error("Change Your Network to Sepolia Network");
        // }
        if (needSigner) {
          const ethersProvider = new BrowserProvider(walletProvider);
          const signer = await ethersProvider.getSigner();
          return signer;
        }
        return signer;
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (isConnected) {
      checkapproval();
    }
    // connectWallet()
    // getPhaseTime();
    // getStageTime();
  }, [dayss]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const stages = dayss;
      let nextStage = currentPhase;

      const startIndex = currentPhase - 1;
      const endIndex = currentPhase == 6 ? startIndex : startIndex + 1;
      const startTime = stages[startIndex] * 1000;
      const endTime = stages[endIndex] * 1000;

      setCurrentStage(nextStage);

      let t;
      if (now < startTime) {
        t = startTime - now;
      } else if (now < endTime) {
        t = endTime - now;
      } else {
        setCheckT(!checkT);
        t = 0;
      }

      const days = Math.floor(t / (1000 * 60 * 60 * 24));
      const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((t % (1000 * 60)) / 1000);
      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, [currentPhase, currentStage]);

  useEffect(() => {
    connectWallet();
  }, [checkT]);

  const approval = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      if (isConnected) {
        if (chainId != chainIds) {
          toast.dismiss();
          toast.error("Please select binance smart chain");
          return;
        }

        const contract = new Contract(
          usdtTokenAndAddress,
          usdtTokenAbi,
          signer
        );

        let amttt = amount;
        if (typeof amount == "number") {
          amttt = amount.toString();
        }

        if (amttt < 1) {
          toast.dismiss();
          toast.error("Enter minimum 1 USDT allowed");
          return;
        }
        setBtnDisable(true);
        const tx = await contract.approve(piratesIdoAddress, parseEther(amttt));

        await tx.wait();
        toast.success("Approval successful");
        checkapproval();

        setBtnDisable(false);
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }
    } catch (err) {
      setBtnDisable(false);
      console.error(err);
    }
  };
  const checkapproval = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();

      const contract = new Contract(
        usdtTokenAndAddress,
        usdtTokenAbi,
        provider
      );

      const balance = await contract.allowance(address, piratesIdoAddress);

      setAllowance(formatUnits(balance, 18));

      if (formatUnits(balance, 18) >= parseFloat(amount)) {
        setShows(true);
      }
    } catch (err) {
      console.error(err);
    }
  };
  function redirect() {
    setTimeout(function () {
      window.location.reload();
    }, 1500);
  }
  const transferAmount = async () => {
    try {
      await checkapproval();
      const signer = await getSignerOrProvider(true);

      if (isConnected) {
        if (chainId != chainIds) {
          toast.dismiss();
          toast.error("Please select binance smart chain");
          return;
        }

        const regex = /^[1-9][0-9]*$/;
        const isValid = regex.test(amount);

        if (isValid) {
          let amttt = amount;
          if (typeof amount == "number") {
            amttt = amount.toString();
          }
          const contract = new Contract(
            piratesIdoAddress,
            piratesIdoAbi,
            signer
          );
          const parsedAmount = parseEther(amttt);

          const tx = await contract.buyTokens(parsedAmount);

          setBtnDisable(true);

          const transactionHash = tx.hash;
          if (transactionHash) {
            await tx.wait();

            toast.success("Buy success");

            setBtnDisable(false);
            redirect();
          }
        } else {
          toast.dismiss();
          toast.error("Enter valid amount");
          setAmountErr("Enter valid amount");
        }

        // redirect();
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }
    } catch (err) {
      // setSpinner(false);
      setBtnDisable(false);

      if (
        err.message.includes(
          `execution reverted: "ERC20: transfer amount exceeds balance"`
        )
      ) {
        toast.dismiss();
        toast.error("Transfer amount exceeds balance");
      }
      if (err.message.includes(`execution reverted: "IDO is not active"`)) {
        toast.dismiss();
        toast.error("IDO is not active");
      }
    }
  };

  const handleButtonClick = () => {
    if (!shows) {
      approval();
    } else {
      transferAmount();
    }
  };
  const handleChange = (e) => {
    checkapproval();
    const { value } = e.target;

    if (parseFloat(allowance) >= parseFloat(value)) {
      setShows(true);
    } else {
      setShows(false);
    }
    setAmount(value);
    if (value === "") {
      setAmountErr("This field is required");
    } else {
      setAmountErr("");
    }
  };
  const maxAmt = (amt) => {
    if (amt != 0) {
      setAmountErr("");
    }

    setAmount(amt);
    if (parseFloat(allowance) >= parseFloat(amt)) {
      setShows(true);
    } else {
      setShows(false);
    }
  };

  const getPhaseTime = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer?.getAddress();

      const contract = new Contract(piratesIdoAddress, piratesIdoAbi, provider);

      const phaseAlloc = await contract.phaseAllocations(currentStage);

      setPhaseAlloca(formatUnits(phaseAlloc, 18));

      const contracts = new Contract(
        usdtTokenAndAddress,
        usdtTokenAbi,
        provider
      );
      const tokenBalance = await contracts.balanceOf(address);
      let bal = formatUnits(tokenBalance, 18);
      const balance = parseFloat(bal);

      setBalance(balance.toFixed(2));
    } catch (err) {
      console.error(err);
    }
  };
  const validNumber = (e) => {
    if (!/[\d.]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const getStageTime = async () => {
    try {
      // let allowance = await token.methods
      // .allowance(userAddr, contractAddress)
      // .call({ from: userAddr });
      let provider;
      let address;
      if (isConnected) {
        const signer = await getSignerOrProvider(true);
        provider = await getSignerOrProvider();
        await signer?.getAddress();
        const contract = new Contract(
          piratesIdoAddress,
          piratesIdoAbi,
          provider
        );
        const phaset = await contract.showAllPhaseStartTimes();

        let newArray = [];

        // Push each value into the newArray
        phaset.forEach((value) => {
          newArray.push(value);
        });
        let separatedNumbers = [];
        let newArrays = newArray.map((str) => String(str).replace("n", ""));
        newArrays.forEach((str) => {
          separatedNumbers.push(parseInt(str));
        });
        setDayss(separatedNumbers);
        const currentph = await contract.getCurrentPhase();

        let modifiedStr = String(currentph).replace("n", "");
        modifiedStr = parseInt(modifiedStr);
        setCurrentPhase(modifiedStr);
      } else {
        const tokenContract = new web3.eth.Contract(
          piratesIdoAbi,
          piratesIdoAddress
        );

        tokenContract.methods
          .showAllPhaseStartTimes()
          .call()
          .then((phaset) => {
            let newArray = [];

            // Push each value into the newArray
            phaset.forEach((value) => {
              newArray.push(value);
            });
            let separatedNumbers = [];
            let newArrays = newArray.map((str) => String(str).replace("n", ""));
            newArrays.forEach((str) => {
              separatedNumbers.push(parseInt(str)); // Convert each string to a number using parseInt
            });

            setDayss(separatedNumbers);
          })
          .catch((error) => {
            console.error("Error:", error);
          });

        tokenContract.methods
          .getCurrentPhase()
          .call()
          .then((currentph) => {
            let modifiedStr = String(currentph).replace("n", "");
            modifiedStr = parseInt(modifiedStr);
            setCurrentPhase(modifiedStr);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const connectWallet = async () => {
    try {
      await getStageTime();
      await getPhaseTime();
    } catch (err) {
      // console.error(err);
    }
  };

  function isValidAmount(amount) {
    return !isNaN(Number(amount)) && Number(amount) >= 0;
  }

  function calculatePiratesAmount(amount, currentPhase) {
    switch (currentPhase) {
      case 1:
        return Number(amount) / 0.0000000001;
      case 2:
        return Number(amount) / 0.000000009;
      case 3:
        return Number(amount) / 0.000000001;
      case 4:
        return Number(amount) / 0.000000009;
      case 5:
        return Number(amount) / 0.000000019;
      default:
        return Number(amount) / 0.00000005;
    }
  }
  const buttonStyles = {
    backgroundColor: "#ffffff", 
    border: "1px solid #d1d1d1", 
    borderRadius: "12px", 
    padding: "8px 16px", 
    margin: "0 4px", 
    cursor: "pointer", 
    fontWeight: "bold", 
    color: "#606060", 
  };

  const selectedButtonStyles = {
    ...buttonStyles,
    backgroundImage: "linear-gradient(180deg, #ff94ff 0%, #70d9d5 100%)",
    color: "#000", 
  };
  const handleRangeSelect = (range) => {
  
    const rangeData = {
      "1 Day": [0.00000001, 0.0000009, 0.00000010, 0.00000090, 0.0000019, 0.000050],
      "1 Week": [0.000002, 0.000109, 0.000010, 0.00090,0.00019,0.0050],
      "1 Month": [0.0001, 0.009, 0.00010, 0.00090,0.0019,0.00050],
      "All Time": [0.0001, 0.00009, 0.000010, 0.00090,0.00019,0.00050],
    };

    setSelectedRange(range);
    setData({
      ...data,
      datasets: [
        {
          ...data.datasets[0],
          data: rangeData[range],
        },
      ],
    });
  };

  
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', { minimumFractionDigits: 10 }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: false,
        ticks: {
          callback: function (value) {
            return value.toLocaleString('fullwide', {useGrouping: false, minimumFractionDigits: 10});
          },
        },
      },
    },
  };
  
  

  
  return (
    <>
      <Header />
      <>
        <img src="img/homebg.png" alt="homebg" className="homebg" />

        <header className="home" id="home" data-scroll-index={1}>
          <div className="banner_content">
            <div className="container">
              <div className="row     align-items-center">
                <div className="col-md-6   ">
                  <h1 className="mb-2 mb-md-3">
                    Step Into The Future with <span>Pirates Finance</span>
                  </h1>
                  <p>
                    Pirates Finance innovates in DeFi with Smart Crypto Payment
                    Gateway, AI-Powered Peer-to-Peer (P2P) Lending and
                    Multi-Chain Non-Custodial Highly Secured DeFi Wallet, aiming
                    to redefine global transactions.
                  </p>
                  <a
                    href="pirates-whitepaper.pdf"
                    target="_blank"
                    className="btn btn2 mt-3 mr-md-4 mr-3"
                  >
                    Whitepaper
                  </a>
                  <a
                    href="business-plans.pdf"
                    target="_blank"
                    className="btn btn2 mt-3"
                  >
                    Business Plan
                  </a>
                  {/* <h3 className="mt-4 mt-md-5">
                    Burn 🔥 Feature ? Why Pirates.
                  </h3>
                  <p>
                    We burn monthly and we airdrop winners monthly and referral
                    program $2000 usd referral in coin after conditions met.
                  </p> */}

                  <h4 className="mt-4 mt-md-5 ">
                    <span className="g_text">Coming soon staking feature</span>
                  </h4>

                  <div className="banner_form  text-left p-md-4">
                    <div class="qr-btn d-flex align-items-center">
                      <div class="qr-text pr-sm-4 pr-md-5">
                        <h6 className="h4">
                          Scan QR Code &amp; <br />
                          See The Contract
                        </h6>
                        <p class="mb-0 mt-2">
                          0xE54EbCE89b5912375fA348c0Be3d4584275b8444
                        </p>
                      </div>
                      <div class="qr ml-auto">
                        <img src="img/qr.png" alt="img" class="img-fluid" />
                      </div>
                    </div>
                  </div>
                
                </div>
                <div className="col-md-6   mt-md-0 mt-5 ml-auto">
                  <div className="banner_form">
                    {/* <div className="banner_form_in">
                      <div className="timer d-inline-flex " role="timer">
                        <div className="t_box">
                          <div className="box">
                            <p id="day">
                              {memoizedTimeLeft.days
                                .toString()
                                .padStart(2, "0")}
                            </p>
                            <span className="smalltext">Days</span>
                          </div>
                        </div>
                        <div className="t_box">
                          <div className="box">
                            <p id="day">
                              {memoizedTimeLeft.hours
                                .toString()
                                .padStart(2, "0")}
                            </p>
                            <span className="smalltext">Hours</span>
                          </div>
                        </div>
                        <div className="t_box">
                          <div className="box">
                            <p id="day">
                              {memoizedTimeLeft.minutes
                                .toString()
                                .padStart(2, "0")}
                            </p>
                            <span className="smalltext">Minutes</span>
                          </div>
                        </div>
                        <div className="t_box">
                          <div className="box">
                            <p id="day">
                              {memoizedTimeLeft.seconds
                                .toString()
                                .padStart(2, "0")}
                            </p>
                            <span className="smalltext">Seconds</span>
                          </div>
                        </div>
                      </div>

                      <h5 className="g_text m-0 mt-2">
                        <b>BUY NOW BEFORE PRICE INCREASES!</b>
                      </h5>
                    </div> */}
                    <h3>
                      <span className="g_text">Pirates </span> Presale Stage
                    </h3>
                    <h4 className="mt-3">
                      Current Stage Price = $
                      {currentPhase === 1
                        ? "0.0000000001"
                        : currentPhase === 2
                        ? "0.000000009"
                        : currentPhase === 3
                        ? "0.0000000010"
                        : currentPhase === 4
                        ? "0.0000000090"
                        : currentPhase === 5
                        ? "0.000000019"
                        : "0.000000050"}
                    </h4>
                    <h4 className="mt-3">
                      {/* 1 Pirates = ${priceusdt} */}
                      Next Stage Price = $
                      {currentPhase + 1 === 2
                        ? "0.000000009"
                        : currentPhase + 1 === 3
                        ? "0.0000000010"
                        : currentPhase + 1 === 4
                        ? "0.0000000090"
                        : currentPhase + 1 === 5
                        ? "0.000000019"
                        : "0.000000050"}
                    </h4>
                    <div className="pro_man mt-2 d-flex">{stages}</div>
                    <h5 className="mt-3">
                      {/* USDT Raised: <span>{ownerBlanceusdt}</span> <br />
                    Tokens Sold: <span>{totalVal - ownerBlance}</span> */}
                    </h5>
                    <ul className="nav mb-4 mt-3">
                      {/* <li className="nav-item ml-0">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#Buy">
                            <img src="img/binance-tab.png" alt="coin" />
                            BNB
                          </a>
                        </li> */}
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#Sell"
                        >
                          <img src="img/usdt-tab.png" alt="coin" />
                          usdt
                        </a>
                      </li>
                    </ul>
                    {/* Tab panes */}
                    <div className="tab-content">
                      <div id="Buy" className=" tab-pane active">
                        <div className="mb-3">
                          <div className="form-group">
                            <label className="text-left w100">
                              Amount in USDT You Pay:{" "}
                            </label>
                            <div className="input_amunt">
                              <input
                                className="form-control"
                                onChange={handleChange}
                                name="amount"
                                onKeyPress={validNumber}
                                value={amount}
                                placeholder="Enter Amount"
                              />
                              {amountErr && (
                                <span className="text-danger">{amountErr}</span>
                              )}
                              <button
                                type="submit"
                                className="btn btn1"
                                onClick={() => maxAmt(balance)}
                              >
                                Max
                              </button>
                              <img
                                className="input_icon"
                                src="img/usdt.png"
                                alt="coin"
                              />
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <label className="text-left w100">
                              Amount in Pirates You Receive:
                              {currentPhase === 1
                                ? Number(amount) / 0.0000000001
                                : currentPhase === 2
                                ? Number(amount) / 0.000000009
                                : currentPhase === 3
                                ? Number(amount) / 0.000000001
                                : currentPhase === 4
                                ? Number(amount) / 0.000000009
                                : currentPhase === 5
                                ? Number(amount) / 0.000000019
                                : Number(amount) / 0.00000005}
                            </label>
                            <div className="input_amunt">
                              <input
                                type="number"
                                disabled={true}
                                value={
                                  currentPhase === 1
                                    ?Number(amount) / 0.0000000001
                                    : currentPhase === 2
                                    ?Number(amount) / 0.000000009
                                    : currentPhase === 3
                                    ?Number(amount) / 0.000000001
                                    : currentPhase === 4
                                    ?Number(amount) / 0.000000009
                                    : currentPhase === 5
                                    ?Number(amount) / 0.000000019
                                    :Number(amount) / 0.00000005
                                }
                                className="form-control "
                                name="amount"
                                placeholder={0}
                              />
                              <img
                                className="input_icon"
                                src="img/coin.png"
                                alt="coin"
                              />
                            </div>
                          </div> */}
                          <div className="form-group">
                            <label className="text-left w100">
                              Amount in Pirates You Receive:
                              {isValidAmount(amount)
                                ? calculatePiratesAmount(amount, currentPhase)
                                : "Invalid Amount"}
                            </label>
                            <div className="input_amunt">
                              <input
                                type="number"
                                disabled={true}
                                value={
                                  isValidAmount(amount)
                                    ? calculatePiratesAmount(
                                        amount,
                                        currentPhase
                                      )
                                    : ""
                                }
                                className="form-control"
                                name="amount"
                                placeholder={0}
                              />
                              <img
                                className="input_icon"
                                src="img/coin.png"
                                alt="coin"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mx-auto mt-4">
                          {/* <button onClick={transferSOL} type="submit" className="btn btn1 w-100">
                          Transfer
                        </button> */}

                          <button
                            type="submit"
                            className="btn1 w-100"
                            onClick={handleButtonClick}
                          >
                            {!shows ? "Approve" : "Transfer"}
                          </button>
                        </div>
                       
                      </div>
                      <div id="Sell" className=" tab-pane   ">
                        <div className="mb-3">
                          <div className="form-group">
                            <label className="text-left w100">
                              Amount in USDT You Pay:
                            </label>
                            <div className="input_amunt">
                              {/* <input type="number" className="form-control " value={amounts}
                              onChange={handleAmountChanges} name="amount" placeholder="Enter Amount" /> */}
                              <input
                                type="number"
                                className="form-control "
                                name="amount"
                                placeholder="Enter Amount"
                              />
                              <button type="submit" className="btn btn1 ">
                                Max
                              </button>
                              <img
                                className="input_icon"
                                src="img/usdt.png"
                                alt="coin"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="text-left w100">
                              Amount in Pirates You Receive:{" "}
                            </label>
                            <div className="input_amunt">
                              {/* <input type="number" className="form-control " disabled={true} value={recivetokens}
                              name="amount" placeholder={0} /> */}
                              <input
                                type="number"
                                className="form-control "
                                disabled={true}
                                name="amount"
                                placeholder={0}
                              />
                              <img
                                className="input_icon"
                                src="img/coin.png"
                                alt="coin"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mx-auto mt-4">
                          {/* <button type="submit" onClick={transferSPLToken} className="btn btn1 w-100">
                          Transfer Token
                        </button> */}
                          <button type="submit" className="btn btn1 w-100">
                            Transfer Token
                          </button>
                        </div>
                        <div className="mx-auto mt-4">
                          <button type="submit" className="btn btn3 w-100">
                            Getting Error? Click Here
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-6 mt-3">
                            <button type="submit" className="btn btn3 w-100">
                              How To Buy?
                            </button>
                          </div>
                          <div className="col-6 mt-3">
                            <button type="submit" className="btn btn3 w-100">
                              Win $333k
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>


        <div className="container">
        <div className="row mt-5">
        <div className="col-md-6 m-auto">
               <div className="usp_box_box">
             
                          <Line data={data} options={chartOptions} />
                      
                        <div className="mt-3 text-center">
                          {["1 Day", "1 Week", "1 Month", "All Time"].map( 
                            (range) => (
                              <button
                                key={range}
                                onClick={() => handleRangeSelect(range)}
                                style={
                                  selectedRange === range
                                    ? selectedButtonStyles
                                    : buttonStyles
                                }
                              >
                                { range}
                              </button>
                            )
                          )}
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>



        <Featured />
        <Ecosystem />
        <Faq />
      </>
      <Footer />
    </>
  );
};
