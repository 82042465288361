import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./components/HomePage/Home";
import TermsofUse from './components/TermsUse/TermsofUse';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import CookiesPolicy from './components/CookiesPolicy/CookiesPolicy';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { Staking } from "./components/Staking/Staking";
import { Airdrop } from "./components/Airdrop/Airdrop";
function App() {
  return <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/terms-of-use' element={<TermsofUse/>} />  
     <Route path='/privacy-policy' element={<PrivacyPolicy/>} />  
     <Route path='/cookies-policy' element={<CookiesPolicy/>} />  
     <Route path='/staking' element={<Staking/>} />
     <Route path='/airdrop' element={<Airdrop/>} />
      </Routes>
    </Router>
    <ToastContainer autoClose={1000} />
    </>
}

export default App;
