const StakingInfoBox = ({ stakeamount,rewardAmt, tokenbalance, daysLeft, apr }) => (
    <div className="staking-tab-content">
    
      <div className="info-box d-flex justify-content-between">
        <div className="info-left">
          <ul className="list-unstyled">
            <li>
              <strong>Staked Amount : </strong> {stakeamount} PIRATES
            </li>
            <li>
              <strong>Reward Amount : </strong> {parseFloat(rewardAmt)} PIRATES
            </li>
  
            <li>
              <strong>Wallet Balance : </strong>{" "}
              {tokenbalance === undefined ? "0" : tokenbalance} PIRATES
            </li>
            <li>
              <strong> Days Left in Maturity : </strong>{" "}
              {daysLeft <= 0 ? "0" : daysLeft}{" "}
            </li>
          </ul>
        </div>
        <div className="info-right d-flex flex-column">
          <span>{apr}%</span>
          <span>APR</span>
        </div>
      </div>
    </div>
  );
  
  export default StakingInfoBox;