import React from "react";
import { Header } from "../Common/Header";
import { Footer } from "../Common/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <section className="in_page bg terms_in">
        <div className="container">
          <div className=" row  mb-4">
            <div className="col-md-4 m-auto text-center">
              <h2 className=" hadding   in_hadding">Privacy Policy</h2>
            </div>
          </div>
          <p>
            This Privacy Policy describes how Pirates and the Pirates token
            ("we," "us," or "our’) collects, uses, and discloses your personal
            information when using our website, services, and token. By using
            the Pirates website, you agree to the practices described in this
            Privacy Policy.
          </p>

          <h4> 1. Inforrnation We Collect</h4>
          <p>
            1.1 User-Provided Information: We may collect information provided
            by users during the creation of an account or when engaging in
            transactions. This may include personal information such as your
            name, email address, and wallet address.
          </p>
          <p>
            1.2 Transaction Information: When you engage in transactions using
            SPirates, we collect information about the transactions, including
            the amount, recipient's wallet address, and timestamp.
          </p>
          <p>
            1.3 Log Data: Like many websites and applications, we collect log
            data, including your IP address, browser type, and the pages you
            visit on the Pirates.
          </p>
          <p>
            1.4 Cookies: We use cookies to enhance your experience on the
            Pirates site. Cookies are small pieces of data stored on your device
            that help us track and analyze usage patterns. For more information,
            please visit our Cookies Policy document.{" "}
          </p>

          <h4>2. How We USe Your Information </h4>
          <p>
            2.1 Transaction Processing: We use your information to facilitate
            transactions on Pirates, including verifying transactions,
            preventing fraud, and maintaining the security of our platform.
          </p>
          <p>
            2.2 Communication: We may use your email address to send important
            updates, announcements, and information related to the Pirates
            token.
          </p>
          <p>
            2.3 Improving Services: We use collected data to analyze usage
            patterns and improve the functionality and performance of Pirates
            and the Pirates token.
          </p>

          <h4>3. Information Sharing </h4>
          <p>
            3.1 Service Providers: We may share your information with
            third-party service providers who assist us in operating the Pirates
            website and Pirates token, conducting our business, or servicing
            you.
          </p>
          <p>
            3.2 Legal Compliance: We may disclose your information to comply
            with applicable laws, regulations, or legal processes.
          </p>
          <p>
            3.3 Security: We take reasonable steps to protect your information,
            but no method of transmission over the Internet or electronic
            storage is 100% secure. Therefore, we cannot guarantee absolute
            security.
          </p>

          <h4>4. Data Security</h4>
          <p>
            4.1. We implement reasonable security measures to protect your
            information from unauthorized access, alteration, or destruction.
          </p>
          <p>
            4.2. Your information may be transferred to, and maintained on,
            computers located outside of your state, province, country, or other
            governmental jurisdiction where the data protection laws may differ.
          </p>

          <h4>5. Your Rights </h4>
          <p>
            You may have certain rights regarding your personal information,
            including the right to access, correct, or delete your personal
            data, subject to applicable law.
          </p>

          <h4> 6. Changes to the Privacy Dolicy</h4>
          <p>
            We reserve the right to update this Privacy Policy at any time.
            Changes will be effective immediately upon posting on the Pirates
            site. Your continued use of Pirates after the posting of changes
            constitutes acceptance of those changes.
          </p>

          <h4> 7. Contact Us</h4>
          <p>
            If you have any questions about these Terms, please contact us at
            contact@pirates.com
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
