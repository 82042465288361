import React, { useEffect, useState, useRef } from "react";
import { Header } from "../Common/Header";
import { Footer } from "../Common/Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  tokenabi,
  tokenaddress,
} from "../constant";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits, parseEther,parseUnits } from "ethers";
import { ethers } from 'ethers';


export const Airdrop = () => {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [userAddress , setuserAddress] = useState();
  const [amount , setAmount]= useState();
  useEffect(()=>{
     if (address && address.toLowerCase() !== "0x67EcF5B832E14e80DEce77458FDD251Ed910405c".toLowerCase()) {
      window.location.href = '/';
    }
},[address])

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      toast.error(error);
      console.error("Error in getWeb3Provider:", error);
      throw error; // Propagate the error to the caller or handle as needed
    }
  };
  const handleChange = async(e) =>{
setuserAddress(e.target.value);
  }
    const handleChange1 = async(e) =>{
setAmount(e.target.value);
  }
  const transferamount = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();
      console.log(      typeof(amount)
,"typeo")
          const totalAmount = parseUnits(amount.toString(), 18);
      const contract = new Contract(tokenaddress, tokenabi, provider);
     const tx = await contract.transfer(userAddress,totalAmount ) ;
      await tx.wait();
            toast.success("Transaction successful");

    } catch (err) {
      console.error(err);
    }
  };
console.log(address,"address")
  return (
    <>
      <Header />

      <section className="in_page bg ">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-md-6  mb-4 m-auto">
              <h2 className=" hadding mb-2 mt-2 mr-md-4 mb-4 text-center ">
              Free  <span className="color_g"> Airdrops</span> 
              </h2>


              <div className="box_mansf">

              <div class="input_amunt form-group">
                <label class="text-left w100">Wallet Address</label>
                <input type="text" class="form-control" name="amount" onChange={handleChange} placeholder="Enter Wallet Address" />
                </div>

                <div class="input_amunt form-group mb-4">
                <label class="text-left w100">Amount in Pirates</label>
                <input type="number" class="form-control" name="amount" onChange={handleChange1} placeholder="Enter Amount" />
                </div>

                <div class=" form-group">
                   <button  onClick={transferamount} class="btn1 w-100">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   
      <Footer />
    </>
  );
};

export default Airdrop;
