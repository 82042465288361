import React from "react";
import { Header } from "../Common/Header";
import { Footer } from "../Common/Footer";

const TermsofUse = () => {
  return (
    <>
      <Header />
      <section className="in_page bg terms_in">
        <div className="container">
          <div className=" row  mb-4">
            <div className="col-md-4 m-auto text-center">
              <h2 className=" hadding   in_hadding">Terms of Use</h2>
            </div>
          </div>
          <p>
            These Terms of Use ("Terms") govern your use of the Pirates website,
            related services, and any associated software (collectively, the
            "Service"). By accessing the website, trading or staking $Pirates,
            you agree to the terms outlined below. These Terms may be updated
            from time to time, and your continued use of our services after any
            changes constitutes acceptance of those changes
          </p>

          <h4>1. The Pirates Token</h4>

          <p>
            The $Pirates and $Pirates2.0 Tokens are digital crypto tokes
            developed on the Ethereum blockchain by the SBSP LTD, who is
            registered in Panama and has the offices in Espana Delta Bank
            Building 6th Floor, Panama City, Panama.
          </p>
          <h4>2. Eligibility</h4>
          <p>
            To use the Service, you must be at least 18 years old or of legal
            age in your jurisdiction. Some jurisdictions do not allow or limit
            the use and trading of cryptocurrencies. You agree that if you
            decide to use our services despite the limitations, you will assume
            full responsibility for your actions.
          </p>

          <h4>3. US of Service</h4>
          <p>
            You agree to use the Service only for lawful purposes and in
            compliance with all applicable laws. You are responsible for all
            activities under your account. You must keep your account
            information secure and notify us immediately of any unauthorized
            use.
          </p>

          <h4>4. Drohibited Activities</h4>
          <p>Users are prohibited from engaging in the following activities:</p>
          <p>
            4.1 Illegal Activities: Any activity that violates applicable laws
            and regulations.
          </p>
          <p>
            4.2 Fraud: Any fraudulent or deceptive activity, including but not
            limited to market manipulation.
          </p>
          <p>
            4.3 Unauthorized Access: Unauthorized access to the Pirates systems,
            networks, or accounts.
          </p>

          <h4>5. Cryptoeurrencies Risks</h4>
          <p>
            5.1. You acknowledge that cryptocurrency investments are high-risk
            and that you may lose some or all of your money. The value of
            $Pirates and the $Pirates Token can fluctuate greatly, SBSP is not
            responsible for any losses you incur.
          </p>
          <p>
            5.2. $Pirates offers a staking feature, allowing users to lock up
            their tokens to support network operations and receive staking
            rewards. By participating in staking, users agree to the following:
          </p>
          <p>
            5.2.1. Rewards: Users will receive staking rewards based on the
            staked amount and the duration of the staking period
          </p>
          <p>
            5.2.2. Risks: Staking involves certain risks, including the
            potential loss of staked tokens. Users should carefully consider
            these risks before participating in staking.
          </p>

          <h4>6. No Warranties</h4>
          <p>
            The Service is provided “as is" and without any warranties, express
            or implied. Pirates disclaims all warranties, including, but not
            limited to, implied warranties of merchantability and fitness for a
            particular purpose.
          </p>
          <h4>7. Lirnitation of Liability</h4>
          <p>
            Pirates shall not be liable for any indirect, incidental, special,
            consequential, or punitive damages, including without limitation,
            loss of profits, data, use, goodwill, or other intangible losses.
          </p>
          <h4>8. Brivaey Doliey</h4>
          <p>
            Your use of the Pirates site is governed by our Privacy Policy,
            which can be found on the website. Please review the Privacy Policy
            to understand how we collect, use, and disclose information.
          </p>
          <h4>9. Arnendrnents</h4>
          <p>
            Pirates reserves the right to modify these Terms at any time without
            notice. However, the date of the last update would be reflected in
            this document.
          </p>
          <h4>10. Governing Law</h4>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of Panama. Any disputes arising from or in connection with
            these Terms shall be resolved through arbitration in accordance with
            the rules of the state of Panama.
          </p>
          <h4>11. Contact Us</h4>
          <p>
            If you have any questions about these Terms, please contact us at
            contact@pirates.com
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default TermsofUse;
