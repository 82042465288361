import React from "react";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { Link } from "react-router-dom";
// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "68d73058b2229fa488959eac1c1092cc";

// 2. Set chains
const bscTestnet = {
  chainId: 97,
  name: "Binance Smart Chain Testnet",
  currency: "BNB",
  explorerUrl: "https://testnet.bscscan.com/",
  rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
};

// 3. Create modal
const metadata = {
  name: "Pirates",
  description: "Pirates",
  url: "https:pirates.com", // origin must match your domain & subdomain
  icons: ["https://bitglobal.bngvogue.com/pirates/img/favicon.ico"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [bscTestnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

const numberOfCircles = 9;

export const Header = () => {
  return (
    <>
      <div className="area">
        <ul className="circles">
          {[...Array(numberOfCircles)].map((_, index) => (
            <li key={index}>
              <img src="img/i_up.png" alt={`Circle ${index + 1}`} />
            </li>
          ))}
        </ul>
      </div>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="img/logo.png" alt="header-Logo" className="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
          >
            <i className="fa fa-bars" aria-hidden="true" />
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ml-auto line">
            <li className="nav-item">
                <a
                  className="nav-link active"
                  href="/how-to-buy.pdf"
                  // onClick={scrollToAbout}
                >
                  How to buy?
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link active"
                  href="/#Ecosystem"
                  // onClick={scrollToAbout}
                >
                  Ecosystem
                </a>
              </li> */}
              <li className="nav-item ">
                <a className="nav-link " href="/#Tokenomics">
                  Tokenomics
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="pirates-whitepaper.pdf"
                  target="_blank"
                >
                  {" "}
                  Whitepaper
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#faq">
                  {" "}
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact Us
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/staking">
                 Staking
                </Link>
              </li>
             
              <li className="nav-item ml-md-4">
                <div className="btn1 p-0">
                 
                  <w3m-button balance="hide" />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div behavior="scroll" className="highlight2 mt-1">
        <marquee behavior="scroll">
        Creating New Millionaires!
        </marquee>
        </div>
     
        <div className="highlight">
          Buy and Stake for 100% Annual Rewards!<br/>
          {/* <span className=""> Liquid Staking Coming Soon</span> */}
        </div>
        
      </nav>
    </>
  );
};
