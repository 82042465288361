import React from "react";

export const Footer = () => {
  return (
    <>
      <section
        className="contact text-center footer"
        id="contact"
        data-scroll-index={6}
      >
        <div className="container">
          <div className="  wow fadeInUpBig  animated" data-wow-delay="0.2s">
            <img src="img/logo.png" alt="header-Logo" className="" />
          </div>
          <div className="nav_link mt-4">
            <a href="/terms-of-use"> Terms of Use </a>
            <a href="/privacy-policy"> Privacy Policy</a>
            <a href="/cookies-policy"> Cookies Policy </a>
          </div>
          <div
            className="copyright wow fadeInUpBig  animated"
            data-wow-delay="0.5s"
          >
            © 2024 Pirates 2.0 Voyage Coin. All Rights Reserved
          </div>
          <div className="  wow fadeInUpBig  animated" data-wow-delay="0.5s">
            <div className="nav_link mb-4">
              Contact Us :{" "}
              <a href="mailto:kusamotoshi@proton.me ">
                {" "}
                Kusamotoshi@proton.me{" "}
              </a>
            </div>
          </div>
          <p className="wow fadeInUpBig  animated" data-wow-delay="0.5s">
            Disclaimer:Cryptocurrency may be unregulated in your jurisdiction.
            The value of cryptocurrencies may go down as well as up. Profits may
            be subject to capital gains or other taxes applicable in your
            jurisdiction.
          </p>
          <div
            className="vertical-social  wow fadeInUpBig  animated"
            data-wow-delay="0.5s"
          >
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.facebook.com/61556402785985/posts/122096333936213426/?substory_index=904775364510424&mibextid=rS40aB7S9Ucbxw6v"
                >
                  <i class="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.instagram.com/piratesventures/"
                >
                  {" "}
                  <i class="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://twitter.com/VenturesPirates">
                  {" "}
                  <img src="img/twitter.png" />
                </a>
              </li>
              <li>
                <a target="_blank" href="#">
                  <i className="fa fa-youtube" />
                </a>
              </li>
              <li>
                <a target="_blank" href="#">
                  <i className="fa fa-telegram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
