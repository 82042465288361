import React from "react";
import { Header } from "../Common/Header";
import { Footer } from "../Common/Footer";

const CookiesPolicy = () => {
  return (
    <>
      <Header />
      <section className="in_page bg terms_in">
        <div className="container">
          <div className=" row  mb-4">
            <div className="col-md-4 m-auto text-center">
              <h2 className=" hadding   in_hadding">Cookies Policy</h2>
            </div>
          </div>
          <p>
            This Cookies Policy explains how the Pirates uses cookies and
            similar tracking technologies when you visit our website or interact
            with the Pirates website. This policy applies to all users of
            Pirates, and by using our website, you consent to the use of cookies
            as described in this policy.
          </p>

          <h4>1. What are Cookies?</h4>
          <p>
            Cookies are small text files stored on your device (computer,
            tablet, smartphone) when you visit a website. They help websites
            remember information about your visit, like your preferences and
            settings, to enhance your user experience.
          </p>

          <h4>2. How We Use Cookies</h4>
          <p>
            Cookies are small text files stored on your device (computer,
            tablet, smartphone) when you visit a website. They help websites
            remember information about your visit, like your preferences and
            settings, to enhance your user experience.
          </p>
          <ul>
            <li>
              Cookies are small text files stored on your device (computer,
              tablet, smartphone) when you visit a website. They help websites
              remember information about your visit, like your preferences and
              settings, to enhance your user experience.
            </li>
            <li>
              Performance Cookies: Collect information on how visitors use our
              website, identifying most visited pages and potential error
              encounters. These improve our website's performance.
            </li>
            <li>
              Functionality Cookies: Remember your choices (like language or
              region preferences) for enhanced features and personalized
              content.
            </li>
            <li>
              Analytics and Tracking Cookies: Used to gather information about
              website usage, helping us understand user interactions and
              patterns. This includes third-party service providers like Google
              Analytics.
            </li>
            <li>
              Advertising Cookies: Deliver targeted advertisements and track ad
              performance.
            </li>
            <li>
              Third-party Cookies: Set by third parties on content provided by
              them on our website. We have no control over these cookies.
            </li>
          </ul>

          <h4>3. Managing Cookies</h4>
          <ul>
            <li>
              Your Consent: By continuing to use our website, you consent to the
              use of cookies.
            </li>
            <li>
              Opt-Out: Manage cookies through your web browser settings.
              Disabling cookies may affect website functionality.
            </li>
            <li>
              Do Not Track Signals: We currently do not respond to "Do Not
              Track" signals from web browsers.
            </li>
          </ul>

          <h4>4. Third-party Cookies</h4>
          <p>
            Third parties who provide content on our website may set cookies on
            your device. We have no control over these cookies. Please review
            the respective privacy and cookies policies of these third parties
            for more information.
          </p>

          <h4>5. Changes to the Cookies Doliey</h4>
          <p>
            We may update this Cookies Policy from time to time to reflect
            changes in our practices or for operational, legal, or regulatory
            reasons. The date of the last update will be indicated at the
            beginning of this document.
          </p>

          <h4>Contact Us</h4>
          <p>
            For any questions, concerns, or requests regarding this Cookies
            Policy or the use of cookies on our website, please contact us at
            contact@Pirates
          </p>

          <p>
            By using Pirates coin.com and accepting cookies, you acknowledge
            that you have read and understood this Cookies Policy and consent to
            the use of cookies as described herein.
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default CookiesPolicy;
